<template>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.0//EN" "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
    <div class="icon-notification">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.9987 19.5C14.9987 21.1569 13.6556 22.5 11.9987 22.5C10.3419 22.5 8.99874 21.1569 8.99874 19.5M13.7952 6.73856C14.2307 6.28864 14.4987 5.67562 14.4987 5C14.4987 3.61929 13.3795 2.5 11.9987 2.5C10.618 2.5 9.49874 3.61929 9.49874 5C9.49874 5.67562 9.76675 6.28864 10.2022 6.73856M17.9987 11.7C17.9987 10.3209 17.3666 8.99823 16.2414 8.02304C15.1162 7.04786 13.59 6.5 11.9987 6.5C10.4074 6.5 8.88132 7.04786 7.7561 8.02304C6.63089 8.99823 5.99874 10.3209 5.99874 11.7C5.99874 13.9818 5.43288 15.6506 4.72681 16.8447C3.92208 18.2056 3.51972 18.8861 3.53561 19.0486C3.55379 19.2346 3.58726 19.2933 3.73808 19.4036C3.86991 19.5 4.53225 19.5 5.85693 19.5H18.1406C19.4652 19.5 20.1276 19.5 20.2594 19.4036C20.4102 19.2933 20.4437 19.2346 20.4619 19.0486C20.4778 18.8861 20.0754 18.2056 19.2707 16.8447C18.5646 15.6506 17.9987 13.9818 17.9987 11.7Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span class="icon-title">{{title}}</span>
    </div>
</template>

<script>
    export default {
        props: {
            width: {
                type: String,
                required: false
            },
            height: {
                type: String,
                required: false
            },
            title: {
                type: String,
                required: false,
                default: ''
            }
        }
    };
</script>

<style scoped>
    .icon-notification {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 5px;

        &:hover{
            cursor: pointer;
        }
    }

    .icon-title {
        font-size: 15px;
    }
</style>